<template>
  <div v-if="dashboards"
       class="dashboards ">
    <div class="dashboard-infos">
      <q-select v-if="dashboardForSelect.length !== 0"
                v-model="dashboardSelect"
                filter
                color='tertiary'
                :options="dashboardForSelect"
                class="select--input" />
      <div v-else
           class="no-element">
        <span>{{$t('pages.admin.info.dashboard.noDashboard')}}</span>
      </div>
      <div v-if="dashboard && dashboard.name">
        {{$t('pages.admin.info.dashboard.position')}}:
        <span class="title">
          {{dashboardPos(dashboard)}}
        </span>
      </div>
      <div v-if="dashboard && dashboard.name">
        {{$t('pages.admin.info.dashboard.creation')}}:
        <span class="title">
          {{formatCreationDate(dashboard.createdAt)}}
        </span>
      </div>
    </div>
    <span v-if="dashboard &&  dashboardForSelect.length !== 0"
          class="title big">
      DashboardWidget<span v-if='dashboard && dashboard.dashboardWidgets && dashboard.dashboardWidgets.length > 0'>s</span>
    </span>
    <div v-if="widgetDisplay"
         HEAD
         class="dashboard-scroll">
      <Widget v-if="getColumnDashWidgets(1).length !== 0"
              :dashboardWidget="getColumnDashWidgets(1)" />
      <Widget v-if="getColumnDashWidgets(3).length !== 0"
              :dashboardWidget="getColumnDashWidgets(3)" />
      <Widget v-if="getColumnDashWidgets(2).length !== 0"
              :dashboardWidget="getColumnDashWidgets(2)" />
      <Widget v-if="getColumnDashWidgets(4).length !== 0"
              :dashboardWidget="getColumnDashWidgets(4)" />
    </div>
    <div v-else
         class='no-element'>
      <span>{{$t('pages.admin.info.dashboard.widget.noWidget')}}</span>
    </div>
    <div v-if="dashboards.length !== 0"
         class="button--container">
      <div type="button"
           class="copy-button"
           @click="toogleCopyDashboard()">
        {{$t('pages.admin.info.dashboard.buttonCopy')}}
      </div>
      <div type="button"
           class="share-button"
           @click="toogleShareDashboard()">
        {{$t('pages.admin.info.dashboard.buttonShare')}}
      </div>
    </div>
    <ModalCopy v-if="dispCopyDashboard"
               :dashboard="dashboard"
               @closeModal="toogleCopyDashboard" />
    <ModalShare v-if="dispShareDashboard"
               :dashboard="dashboard"
               @closeModal="toogleShareDashboard" />
  </div>
</template>

<script>
import Widget from './widget';
import ModalCopy from './modalCopy';
import ModalShare from './modalShare';
import moment from 'moment';
export default {
  name: 'AdminDashboards',
  components: {
    Widget,
    ModalCopy,
    ModalShare,
  },
  data() {
    return {
      dashboardSelect: {},
      dashboard: {},
      dispJson: false,
      json: {},
      dispCopyDashboard: false,
      dispShareDashboard: false,
    };
  },
  computed: {
    widgetDisplay() {
      return this.dashboard && this.dashboard.dashboardWidgets && this.dashboard.dashboardWidgets.length !== 0;
    },
    user() {
      return this.$store.state.admin.selected.user;
    },
    dashboards() {
      return this.$store.state.admin.selected.dashboards;
    },
    dashboardForSelect() {
      const newDash = [];
      this.dashboards.forEach(d => {
        newDash.push({ value: d.dashboard.id, label: d.dashboard.name });
      });
      return newDash;
    },
    ajustHeight() {
      const index = this.dashboards.findIndex(d => d.dashboard.id === this.dashboardSelect);
      const length = this.dashboards[index].dashboard.dashboardWidgets.length / 4;
      if (length > 2) return 'dashboard-heigth';
      else return 'dashboard-margin';
    },
  },

  watch: {
    dashboardSelect(value) {
      const index = this.dashboards.findIndex(d => {
        if (d.dashboard.id === value) return d;
      });
      this.dashboard = this.dashboards[index].dashboard;
    },
    dashboards(value) {
      this.setFirstDashboard(value);
    },
  },
  created() {
    this.setFirstDashboard(this.dashboards);
  },
  methods: {
    setFirstDashboard(value) {
      if (value.length !== 0) {
        this.dashboardSelect = value[0].dashboardId;
        this.dashboard = value[0].dashboard;
      } else {
        this.dashboard = {};
      }
    },
    getColumnDashWidgets(nbr) {
      const index = this.dashboards.findIndex(d => d.dashboardId === this.dashboardSelect);
      const quarter = this.dashboards[index].dashboard.dashboardWidgets.length / 4;
      const second = nbr * quarter;
      const first = (nbr - 1) * quarter;
      const array = this.dashboards[index].dashboard.dashboardWidgets
        .filter((i, index) => index >= Math.ceil(first) && index < second)
        .map(i => {
          return i;
        });
      return array;
    },
    formatCreationDate(date) {
      const d = new Date(date);
      return moment(d).format('L');
    },
    dashboardPos(dashboard) {
      if (!dashboard.position) return this.$t('pages.admin.info.dashboard.noPosition');
      return dashboard.position;
    },
    toogleCopyDashboard() {
      this.dispCopyDashboard = !this.dispCopyDashboard;
    },
    toogleShareDashboard() {
      this.dispShareDashboard = !this.dispShareDashboard;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.select {
  display: flex;
  justify-content: center;
}

.no-element {
  text-align: center;
  width: 100%;
  font-size: $fs-body;
}

.select--input {
  width: 10%;
  height: 2rem;
  border-radius: 5px;
  background-color: white;
  padding-left: 1rem;
}

.dashboards {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.dashboard-infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.title {
  font-weight: $fw-bold !important;
}

.big {
  font-size: $fs-h3;
  margin-bottom: 1rem;
}

.dashboard-scroll {
  display: flex;
  overflow-y: auto;
  justify-content: space-around;
}

.dashboard-margin {
  margin-bottom: 1rem;
}

.dashboard-heigth {
  height: 50rem;
}

.button--container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.copy-button {
  border: $border-thick solid $light;
  background-color: white;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: $secondary;
  width: 14rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.share-button {
  border: $border-thick solid $light;
  background-color: white;
  padding: 0.3rem 2rem;
  border-radius: 15px;
  color: $secondary;
  width: 15rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
</style>