<template>
  <div class="modal-validate">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="column__container">
            <div>
              <span> {{$t('pages.admin.info.feature.modal')}}:</span>
              <input v-model="name"
                     type='text'
                     class='input--name' />
            </div>
            <span v-if="hasError"
                  class="error">{{errorMessage}}</span>
          </div>
          <div class="column__button">
            <div class="button"
                 @click="annule()">
              {{$t('pages.admin.info.feature.cancel')}}
            </div>
            <div class="button"
                 @click="validate()">
              {{$t('pages.admin.info.feature.submit')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
export default {
  name: 'FormAdmin',
  props: {
    featureSelect: {
      type: Object,
      default: () => {},
    },
    userUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      hasError: false,
      errorMessage: '',
    };
  },
  created() {
    if (this.featureSelect) this.name = this.featureSelect.name;
  },
  methods: {
    async validate() {
      if (this.featureSelect && this.name === this.featureSelect.name) {
        this.hasError = true;
        this.errorMessage = this.$t('pages.admin.info.feature.form');

        return;
      }
      try {
        const featureId = this.featureSelect ? this.featureSelect.featureId : -1;
        const { data: feat } = await api.admin.features.createFeature({ featureId: featureId, featureName: this.name });
        this.hasError = false;
        feat.featureId = feat.id;
        const action = this.featureSelect ? 'update' : 'create';
        await this.$store.dispatch('admin/updateFeature', { feature: feat, action: action });
        this.$emit('closeForm');
      } catch (e) {
        this.hasError = true;
        this.errorMessage = e.response.data.errorMsg;
        return;
      }
    },
    annule() {
      this.$emit('closeForm');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}

.column__container {
  display: flex;
  flex-direction: column;
}

.input--name {
  margin-left: 0.8rem;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 0;
}

.error {
  color: red;
  margin-top: 1rem;
}

.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
</style>