<template>
  <div class="modalValidate">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <VueJsonPretty :data="{key:dashboardWidget}"
                         :highlightMouseoverNode="true"
                         :showLength="false"
                         :showLine="false"
                         :showDoubleQuotes="false"
                         :highlightSelectedNode="true" />

          <div class="buttonCenter"
               @click="$emit('closeModal')">
            <span class="button">
              {{$t('pages.admin.info.dashboard.modalCancel')}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
export default {
  name: 'ModalDashboard',
  components: {
    VueJsonPretty,
  },
  props: {
    dashboardWidget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      escapeKeyCode: 27,
    };
  },
  created() {
    window.addEventListener('keyup', e => {
      if (e.keyCode === this.escapeKeyCode) this.$emit('closeModal');
    });
  },
  destroyed() {
    window.removeEventListener('keyup', {});
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: $silver;
  border-radius: 2px;
}

.buttonCenter {
  display: flex;
  justify-content: center;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
</style>