<template>
  <Table v-if="user.name && oapps"
         :datas='oapps'
         :tab="'oapps'"
         :formatData='formatData'
         @validate='validate'>
    <template slot="name-columns">
      <tr>
        <th>
          {{$t('pages.admin.info.oapp.name')}}
        </th>
        <th>
          {{$t('pages.admin.info.oapp.installed')}}
        </th>
        <th>
          {{$t('pages.admin.info.oapp.available')}}
        </th>
      </tr>
    </template>
    <template slot='buttons'>
      <div class="column--button">
        <div class="button validate"
             @click="validate()">
          {{$t('pages.admin.info.feature.submit')}}
        </div>
      </div>
    </template>
  </Table>
</template>

<script>
import Table from './table';
import api from 'api';

export default {
  name: 'OappsWrapper',
  components: { Table },
  computed: {
    user() {
      return this.$store.state.admin.selected.user;
    },
    oapps() {
      return this.$store.state.admin.selected.oapps;
    },
    formatData() {
      const tab = [];
      this.oapps.forEach(o => {
        tab.push({ name: o.name, first: o.installed, second: o.available, selectable: false });
      });
      return tab;
    },
    editOapps() {
      return this.$store.state.admin.editOapps;
    },
  },
  methods: {
    validate() {
      this.editOapps.forEach(async o => {
        if (!this.oapps[o].installed) await api.admin.user.deleteOapp(this.user.uuid, this.oapps[o].id);
        else if (this.oapps[o].installed) await api.admin.user.addOapp({ userUuid: this.user.uuid, oappId: this.oapps[o].id });
      });
    },
  },
};
</script>

<style>
</style>