<template>
  <div class="flex-row">
    <UserList v-if="users.length !== 0" />
    <Tabs v-if="userSelect" />
  </div>
</template>

<script>
import Tabs from './components/tabs';
import UserList from './components/usersList';

export default {
  name: 'Admin',
  components: {
    UserList,
    Tabs,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    users() {
      return this.$store.state.admin.collection.users;
    },
    userSelect() {
      return this.$store.state.admin.selected.user;
    },
  },
  async created() {
    await this.$store.dispatch('admin/fetchUsers');
  },
};
</script>

<style lang="stylus" scoped>
.flex-row {
  display: flex;
  flex-flex-direction: row;
  padding: 3rem 0;
}
</style>