<template>
  <div class="usersList">
    <div class="quasar--select">
      <q-select ref="select"
                v-model="userSelect"
                filter
                color='tertiary'
                :options="usersForSelect"
                class="select--input" />
    </div>
    <div v-if="user.name"
         class="userInfo">
      <span class="margin-line">
        {{$t('pages.admin.info.name')}}:
        <span class="title">
          {{user.name}}

        </span>
      </span>
      <span class="margin-line">
        Email:
        <span class="title">
          {{user.email}}

        </span>
      </span>
      <span class="margin-line">
        Uuid:

        <span class="title">
          {{user.uuid}}

        </span>
      </span>
      <div class="user-groups-SSO">
        {{$t('pages.admin.info.group.title')}}<span v-if="elementLengthBiggerOne(user.groups)">s</span> SSO:
        <ul v-for="(group, key) in user.groups"
            :key="key"
            class="group-names">
          <li class="group-elem">
            {{group.name}}
          </li>
        </ul>
        <span v-if="elementLengthNull(user.groups)"
              class="any-group">
          {{$t('pages.admin.info.group.no')}}
        </span>
      </div>
      <div class="user-nodes">
        {{$t('pages.admin.info.node.title')}}<span v-if="elementLengthBiggerOne(nodes)">s</span>:
        <div v-for="(node, key) in nodes"
             :key="key"
             class="path-node">
          <div class="path">
            <span v-for="(step, ind) in node.path"
                  :key="ind">
              {{step.name}}
              <span v-if="ind !== node.path.length - 1">
                -->
              </span>
            </span>
          </div>
          <div v-for="(group, index) in node.groups"
               :key="index"
               class="group-node">
            {{group . name}}
          </div>
          <span class="date">
            {{$t('pages.admin.info.node.installed')}}
            <span>
              {{dateInstallationFormat(node.updatedAt)}}
            </span>
          </span>
          <div class="node-uuid--container">
            <span class="nodeUuid">
              {{node.node}}
            </span>
            <div v-clipboard:copy="node.node"
                 type="button"
                 class="clipboard"
                 @click='notifCopy()'>
              {{$t('pages.admin.info.node.clipboard')}}
            </div>
          </div>
        </div>
        <span v-if="elementLengthNull(nodes)"
              class="any-group">{{$t('pages.admin.info.node.no')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'UsersList',
  data() {
    return {
      userSelect: {},
      usersFilter: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.admin.selected.user;
    },
    nodes() {
      return this.$store.state.admin.selected.nodes;
    },
    users() {
      return this.$store.state.admin.collection.users;
    },
    usersFirst() {
      return this.$store.state.admin.collection.usersFirst;
    },
    nodesCollecton() {
      return this.$store.state.admin.collection.nodes;
    },
    usersForSelect() {
      const newUsers = [];
      this.usersFilter.forEach(u => {
        newUsers.push({ value: u.uuid, label: u.email });
      });
      return newUsers;
    },
    selectFilter() {
      return this.$refs.select.terms;
    },
    limitDisplay() {
      return this.$store.state.admin.limit;
    },
  },
  watch: {
    async userSelect(uuid) {
      const index = this.users.findIndex(u => u.uuid === uuid);
      await this.$store.dispatch('admin/changeUserSelected', index);
      this.usersFilter = this.usersFirst;
    },
  },
  created() {
    if (this.users.length !== 0) {
      this.userSelect = this.user.uuid;
      this.usersFilter = this.usersFirst;
    }
  },
  mounted() {
    this.$watch('$refs.select.terms', (new_value, old_value) => {
      if (new_value === '') this.usersFilter = this.usersFirst;
      else if (new_value !== '') {
        const users = this.users.filter(u => {
          return u.email.toLowerCase().includes(new_value.toLowerCase());
        });
        if (users.length > this.limitDisplay) users.splice(this.limitDisplay);
        this.usersFilter = users;
      }
    });
  },
  methods: {
    dateInstallationFormat(date) {
      const d = new Date(date);
      return moment(d).format('L');
    },
    elementLengthNull(elem) {
      return elem && elem.length === 0;
    },
    elementLengthBiggerOne(elem) {
      return elem && elem.length > 1;
    },
    notifCopy() {
      this.$q.notify({
        message: this.$t('pages.ticket.toasts.linkPastedClipboard'),
        type: 'positive',
        position: 'bottom-left',
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.usersList {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-right: $border-light solid $light;
}

.date {
  margin-left: 1rem;
}

.quasar--select {
  width: 100%;
  display: flex;
  justify-content: center;
}

.select--input {
  width: 80%;
  border-radius: 5px;
  background-color: white;
  padding-left: 1rem;
  margin-bottom: 2rem;
}

.userInfo {
  display: flex;
  flex-direction: column;
  height: 62rem;
  margin-left: 2rem;
  overflow-y: auto;
  font-size: $fs-body;
}

.title {
  font-weight: $fw-bold;
}

.margin-line {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.user-groups-SSO {
  margin-bottom: 2rem;
}

.user-nodes {
  margin-bottom: 6rem;
}

.group-names {
  margin-top: 0.3rem;
}

.group-elem {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}

.group-node {
  margin-left: 6rem;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  padding: 0.3rem 0;
}

.path-node {
  margin-top: 0.5rem;
}

.path {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}

.node-uuid--container {
  margin: 1rem 0 2rem 0;
  display: flex;
  justify-content: center;
  width: 90%;
  border-radius: 4px;
}

.nodeUuid {
  font-size: $fs-little;
  background-color: $dark;
  color: white;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 5px;
}

.clipboard {
  width: 5rem;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  background-color: $dash-light;
  text-align: center;
  border-radius: 5px;
  margin-left: -0.5rem;
}
</style>
