<template>
  <div class="container--datas">
    <table class="table-data">
      <slot name="name-columns" />
      <tr>
        <th v-for="(column, key) in columns"
            :key="key">
          {{column}}
        </th>
      </tr>
      <tr v-for="(data, key) in formatData"
          :key="key">
        <th class="data noSelect">
          <span v-if="data.selectable"
                :class="editable"
                @click="$emit('openForm', data)">
            {{data.name.toUpperCase()}}
          </span>
          <span v-else>
            {{data.name.toUpperCase()}}
          </span>
        </th>
        <th class="pointer noSelect"
            @click="changeValue(key, 0)">
          <transition name="fade">
            <img v-if="data.first"
                 class="tick"
                 src='@/../public/assets/Tick.svg'
                 alt='Tick table' />
          </transition>
        </th>
        <th class="pointer noSelect"
            @click="changeValue(key, 1)">
          <transition name="fade">
            <img v-if="data.second"
                 class="tick"
                 src='@/../public/assets/Tick.svg'
                 alt='Tick table' />
          </transition>
        </th>
        <img v-if="edit && data.selectable"
             class="cross"
             src='@/../public/assets/Fail.svg'
             alt="Cross for delete feature"
             @click='$emit("openModalValidate", data)' />
      </tr>
    </table>
    <slot name="buttons" />
  </div>
</template>

<script>
export default {
  name: 'TableAdmin',
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    formatData: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$store.state.admin.selected.user;
    },
    editable() {
      return { isUnderline: this.edit };
    },
  },
  methods: {
    changeValue(index, elem) {
      this.$store.dispatch('admin/swapDatas', { uuid: this.user.uuid, index: index, position: elem, tab: this.tab });
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables';

.container--datas {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
}

.table-data {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

th {
  border-border-collapse: collapse;
  border: $border-medium solid $light;
  padding: 0.5rem 0;
}

.data {
  font-weight: $fw-regular;
  width: 50%;
}

.pointer {
  cursor: pointer;
  color: $secondary;
}

.tick {
  width: 1.3rem;
}

.cross {
  width: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.isUnderline {
  cursor: pointer;
  text-decoration: underline;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.column--button {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}

.button:active {
  background-color: $light;
}
</style>