<template>
  <div>
    <Table v-if="user.name"
           :datas='features'
           :tab="'features'"
           :formatData="formatData"
           :edit="edit"
           @openModalValidate='openModalValidate'
           @openForm="openForm">
      <template slot="name-columns">
        <tr>
          <th>
            {{$t('pages.admin.info.feature.name')}}
          </th>
          <th>
            {{$t('pages.admin.info.feature.read')}}
          </th>
          <th>
            {{$t('pages.admin.info.feature.write')}}
          </th>
        </tr>
      </template>
      <template slot="buttons">
        <div v-if="!edit"
             class="column--button">
          <div class="button edit"
               @click="toogleEdit()">
            {{$t('pages.admin.info.feature.edit')}}
          </div>
          <div class="button validate"
               @click="validate()">
            {{$t('pages.admin.info.feature.submit')}}
          </div>
        </div>
        <div v-else
             class="column--button">
          <div class="button edit"
               @click="toogleEdit()">
            {{$t('pages.admin.info.feature.cancel')}}
          </div>
          <div class="button add"
               @click="openForm()">
            {{$t('pages.admin.info.feature.add')}}
          </div>
        </div>
      </template>
    </Table>
    <ModalValidateFeatures v-if="modalValidate"
                           :feature="featureEdit"
                           @closeModal="closeModalValidate" />
    <FormFeatures v-if="form"
                  :featureSelect="featureEdit"
                  :userUuid="user.uuid"
                  @closeForm="closeForm" />

  </div>
</template>

<script>
import Table from './table';
import ModalValidateFeatures from './modalValidateFeatures';
import FormFeatures from './formFeatures';
import api from 'api';
export default {
  name: 'FeaturesWrapper',
  components: { Table, ModalValidateFeatures, FormFeatures },
  data() {
    return {
      modalValidate: false,
      form: false,
      edit: false,
      featureEdit: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.admin.selected.user;
    },
    features() {
      return this.$store.state.admin.selected.features;
    },
    formatData() {
      const tab = [];
      let selectable = true;
      this.features.forEach(f => {
        selectable = f.name === 'admin' ? false : true;
        tab.push({ name: f.name, first: f.read, second: f.write, selectable: selectable });
      });
      return tab;
    },
  },
  methods: {
    async validate() {
      await api.admin.features.update({ userUuid: this.user.uuid, featuresList: this.features });
    },
    toogleEdit() {
      this.edit = !this.edit;
    },
    openModalValidate(data) {
      const feature = this.features.find(d => {
        if (d.name === data.name) return d;
      });
      this.featureEdit = feature;
      this.modalValidate = true;
    },
    closeModalValidate(value) {
      this.featureEdit = {};
      this.modalValidate = false;
    },
    openForm(data) {
      if (!this.edit) return;
      if (data) {
        const feature = this.features.find(d => {
          if (d.name === data.name) return d;
        });
        this.featureEdit = feature;
      } else this.featureEdit = data;
      this.form = true;
    },
    closeForm(value) {
      this.featureEdit = {};
      this.form = false;
    },
  },
};
</script>

<style>
</style>