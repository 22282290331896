<template>
  <div class="tabs">
    <q-tabs animated
            swipeable
            color="secondary"
            align="justify">
      <q-tab slot="title"
             label="Features"
             default
             name='features' />
      <q-tab slot="title"
             label="Oapps"
             name='oapps' />
      <q-tab slot="title"
             label="Dashboards"
             name='dashboards' />
      <q-tab-pane name="features">
        <FeaturesWrapper />
      </q-tab-pane>
      <q-tab-pane name="oapps">
        <OappsWrapper />
      </q-tab-pane>
      <q-tab-pane name="dashboards">
        <AdminDashboard v-if="user.name" />
      </q-tab-pane>
    </q-tabs>
  </div>
</template>

<script>
import FeaturesWrapper from './featuresWrapper';
import OappsWrapper from './oappsWrapper';
import AdminDashboard from './adminDashboard';
export default {
  name: 'TabsAdmin',
  components: { FeaturesWrapper, OappsWrapper, AdminDashboard },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.admin.selected.user;
    },
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.tabs {
  width: 75%;
  margin: 0 auto;
}
</style>