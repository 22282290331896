<template>
  <div class="modal-validate">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <span class="sentence">{{$t('pages.admin.info.feature.modalConfirmation')}} : {{feature.name.toUpperCase()}} ?</span>
          <div class="column__button">
            <div class="button"
                 @click="cancel()">
              {{$t('pages.admin.info.feature.cancel')}}
            </div>
            <div class="button"
                 @click="validate()">
              {{$t('pages.admin.info.feature.submit')}}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';

export default {
  name: 'ModalValidate',
  props: {
    feature: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async validate(feature) {
      await api.admin.features.deleteFeature({ featureId: this.feature.featureId });
      this.$emit('closeModal', 'delete');
    },
    cancel() {
      this.$emit('closeModal', 'cancel');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}

.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}
</style>