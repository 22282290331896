<template>
  <div class="modalValidate">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal--body"
               :class="errorHeight">
            <span>
              {{$t('pages.admin.info.dashboard.share.sentence')}}
            </span>
            <div class="users--select">
              <span>
                {{$t('pages.admin.info.dashboard.share.list')}}:
              </span>
              <q-select ref='selectShare'
                        v-model="userUuid"
                        filter
                        color='tertiary'
                        :options="usersForSelect"
                        class="select--input" />
            </div>
            <div v-if="errorMsg !== ''"
                 class="error">
              {{errorMsg}}
            </div>
          </div>
          <div class="column__button">
            <div class="button"
                 @click="$emit('closeModal')">
              {{$t('pages.admin.info.dashboard.share.cancel')}}
            </div>
            <div class="button"
                 @click="submit()">
              {{$t('pages.admin.info.dashboard.share.submit')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';

export default {
  name: 'ModalShare',
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userUuid: '',
      errorMsg: '',
      usersFilter: [],
    };
  },
  computed: {
    users() {
      return this.$store.state.admin.collection.users;
    },
    usersFirst() {
      return this.$store.state.admin.collection.usersFirst;
    },
    errorHeight() {
      return { bodyError: this.errorMsg !== '' };
    },
    usersForSelect() {
      const users = [];
      this.usersFilter.forEach((u, index) => {
        users.push({ value: u.uuid, label: `${u.name}(${u.email}` });
      });
      return users;
    },
    limitDisplay() {
      return this.$store.state.admin.limit;
    },
  },
  watch: {
    userUuid(uuid) {
      const index = this.users.findIndex(u => u.uuid === uuid);
      if (index > this.limitDisplay) {
        this.$store.commit('admin/userSelectFirst', index);
      }
    },
  },
  mounted() {
    this.usersFilter = this.usersFirst;
    this.$watch('$refs.selectShare.terms', (new_value, old_value) => {
      if (new_value === '') this.usersFilter = this.usersFirst;
      else if (new_value !== '') {
        const users = this.users.filter(u => {
          return u.email.toLowerCase().includes(new_value.toLowerCase());
        });
        if (users.length > this.limitDisplay) users.splice(this.limitDisplay);
        this.usersFilter = users;
      }
    });
  },
  methods: {
    async submit() {
      try {
        await api.admin.user.shareDashboard(this.userUuid, this.dashboard.id);
        const dashboardShare = { ...this.dashboard, userUuid: this.userUuid };
        this.$store.dispatch('admin/shareDashboard', dashboardShare);
        this.$emit('closeModal');
      } catch (e) {
        this.errorMsg = this.$t('pages.admin.info.dashboard.share.error');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.modal-mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 20%;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  width: 40rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
}

.modal--body {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bodyError {
  height: 7rem !important;
}

.users--select {
  display: flex;
  align-items: flex-start;
}

.column__button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
}

.button {
  border: $border-medium solid $light;
  background-color: white;
  border-radius: 10px;
  color: $secondary;
  width: 6rem;
  text-align: center;
  cursor: pointer;
}

.error {
  color: red;
}

.select--input {
  margin-left: 0.3rem;
  width: 70%;
  height: 1.3rem;
  border-radius: 5px;
  background-color: $light;
  padding-left: 1rem;
}
</style>