<template>
  <div>
    <div class="dashTier">
      <div v-for="(dashWidget, key) in dashboardWidget"
           :key="key"
           class="dashWidget">
        <div class="info">
          {{$t('pages.admin.info.dashboard.widget.name')}}:
          <span class="title">
            {{dashWidget.config.name}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.widget.name_widget')}}:
          <span class="title">
            {{dashWidget.widget.name}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.widget.name_oapp')}}:
          <span class="title">
            {{dashWidget.widget.oapp.name}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.creation')}}:
          <span class="title">
            {{dashboardDate(dashWidget.createdAt)}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.position')}} X:
          <span class="title">
            {{ widgetPosX(dashWidget)}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.position')}} Y:
          <span class="title">
            {{ widgetPosY(dashWidget)}}
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.widget.weight')}}:
          <span class="title">
            <span>{{widgetSizeW(dashWidget)}}</span>
          </span>
        </div>
        <div class="info">
          {{$t('pages.admin.info.dashboard.widget.height')}}:
          <span class="title">
            <span>{{widgetSizeH(dashWidget)}}</span>
          </span>
        </div>
        <div class="dashJsonButton">
          <div type="button"
               class="button"
               @click="toogleJson(dashWidget)">
            {{$t('pages.admin.info.dashboard.widget.button')}}
          </div>
        </div>
      </div>

    </div>
    <ModalWidget v-if="dispJson"
                 :dashboardWidget="json"
                 @closeModal='toogleJson()' />
  </div>
</template>

<script>
import ModalWidget from './modalWidget';
import moment from 'moment';
export default {
  name: 'Widget',
  components: {
    ModalWidget,
  },
  props: {
    dashboardWidget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dispJson: false,
      json: {},
    };
  },
  methods: {
    dashboardDate(date) {
      const d = new Date(date);
      return moment(d).format('L');
    },
    widgetSizeW(widget) {
      if (!widget.w) return this.$t('pages.admin.info.dashboard.widget.notAttributed');
      return widget.w;
    },
    widgetSizeH(widget) {
      if (!widget.h) return this.$t('pages.admin.info.dashboard.widget.notAttributed');
      return widget.h;
    },
    widgetPosX(widget) {
      if (widget.x === 0) return widget.x;
      else if (!widget.x) return this.$t('pages.admin.info.dashboard.widget.notAttributed');
      return widget.x;
    },
    widgetPosY(widget) {
      if (widget.y === 0) return widget.y;
      else if (!widget.y) return this.$t('pages.admin.info.dashboard.widget.notAttributed');
      return widget.y;
    },
    toogleJson(dashWidget) {
      if (!this.dispJson) {
        this.json = dashWidget.config;
        this.dispJson = true;
      } else {
        this.json = {};
        this.dispJson = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables';

.title {
  font-weight: $fw-bold !important;
}

.dashTier {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 0;
  border-radius: 10px;
  border: $border-thick solid $light;
  padding: 0.6rem 1rem;
  background-color: white;
  width: 22rem;
  height: 25rem;
}

.dashJsonButton {
  display: flex;
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}

.button {
  border: $border-medium solid $light;
  background-color: $light;
  border-radius: 10px;
  color: $secondary;
  width: 10rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}
</style>